import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../services/auth/authentication.service';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { SupplierQualityModel } from '../models/firestore/supplier-quality.model';
import { SupplierQualityResponse } from '../models/firestore/supplier-quality-interface.model';


@Injectable({
  providedIn: 'root',
})
export class QualityService {
  apiUrl = environment.partialApiUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  idCustomer;

  constructor(private http: HttpClient, private auth: AuthenticationService) {
    this.auth.tokenPayLoadSubject
      .asObservable()
      .subscribe((x) => (this.idCustomer = x.customer));
  }

  addNote(quality: SupplierQualityModel): Observable<SupplierQualityModel>{
    return this.http.post<SupplierQualityModel>(this.apiUrl + '/apontamento-qualificacao', quality);
  }

  getNotesByTaxId(taxId: string): Observable<SupplierQualityResponse> {
    return this.http.get<SupplierQualityResponse>(this.apiUrl + '/apontamento-qualificacao?taxId=' + taxId);
  }

  deleteNotesById(id: number): Observable<SupplierQualityModel>{
    return this.http.delete<SupplierQualityModel>(this.apiUrl + '/apontamento-qualificacao/' + id);
  }

}

import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/auth/authentication.service';
import { SupplierCategoryResponse } from '../models/firestore/supplier-category-interface.model';
import { SupplierCategoryModel } from '../models/supllier-category-model';


@Injectable({ providedIn: 'root' })
export class SupplierCategoryService {

    fullApiUrl = environment.fullApiUrl;
    // partialApiUrl = environment.partialApiUrl;

    constructor(private http: HttpClient, private authentication: AuthenticationService) {}

     public getAllCategories(): Observable<SupplierCategoryResponse> {
         return this.http.get<SupplierCategoryResponse>(this.fullApiUrl + '/supplier-categories');
     }

     public deleCategoryById(categoryId): Observable<any>{
          return this.http.delete<any>(this.fullApiUrl + '/supplier-categories/' + categoryId);
     }

     public deleCategoryFromSupplier(supplierId): Observable<any>{
          return this.http.delete<any>(this.fullApiUrl + '/supplier-categories/fornecedores/' + supplierId);
     }

     public createCategory(category: SupplierCategoryModel): Observable<SupplierCategoryModel>{
          return this.http.post<SupplierCategoryModel>(this.fullApiUrl + '/supplier-categories', category);
     }

     public addCategoryToSupplier(supplierId, categoryId): Observable<any>{
          const url = `${this.fullApiUrl}/supplier-categories/fornecedores/${supplierId}/categorias/${categoryId}`;
          return this.http.patch<any>(url, null);
     }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Http1Service } from '../shared.module';
import { environment } from 'environments/environment';
import { AuthenticationService } from '../services/auth/authentication.service';
import { HttpParams } from '@angular/common/http';
import { TokenPayLoad } from '../models/tokenPayLoad';

@Injectable({ providedIn: 'root' })
export class ElasticsearchService {
  private elasticSearchApi: String = environment.partialApiUrl + '/elastic';
  private idCustomer: string = 'null';

  ORDER_INDEX: string = '/uq-order/';
  DOC_INDEX: string = '/uq-doc/';
  SUPPLIER_INDEX: string = '/uq-supplier/';

  constructor(
    private httpClient: Http1Service,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.tokenPayLoadSubject
      .asObservable()
      .subscribe((token: TokenPayLoad) => {
        if (token.customer) {
          this.idCustomer = token.customer.toString();
        }
      });
  }

  getOrders(
    query: Object,
    params: HttpParams = new HttpParams(),
    type: string = '_search'
  ): Observable<any[]> {
    let url: string = this.elasticSearchApi + this.ORDER_INDEX + type;
    params = params.set('routing', this.idCustomer);
    return this.httpClient.post<any[]>(url, query, { params: params });
  }

  getDocs(
    query: Object,
    params: HttpParams = new HttpParams(),
    type: string = '_search'
  ): Observable<any[]> {
    let url: string = this.elasticSearchApi + this.DOC_INDEX + type;
    params = params.set('routing', this.idCustomer);
    return this.httpClient.post<any[]>(url, query, { params: params });
  }

  getSuppliers(
    query: Object,
    params: HttpParams = new HttpParams(),
    type: string = '_search'
  ): Observable<any[]> {
    let url: string = this.elasticSearchApi + this.SUPPLIER_INDEX + type;
    params = params.set('routing', this.idCustomer);
    return this.httpClient.post<any[]>(url, query, { params: params });
  }

  getElementsWithSrollId(scrollId: string): Observable<any[]> {
    let url: string = this.elasticSearchApi + '/_search/scroll';
    var params: HttpParams = new HttpParams();
    params = params.set('routing', this.idCustomer);
    let query = {
      scroll: '1m',
      scroll_id: scrollId,
    };
    return this.httpClient.post<any[]>(url, query, { params: params });
  }

  // getOrdersCollapseByCnpj(query: any, idCustomer: number):  Observable<any[]>{
  //  return this.httpClient.post<any[]>(this.elasticSearchApi + this.ORDER_INDEX + '_search'  + '?routing=' + idCustomer, query);
  // }
}

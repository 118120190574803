import { ValidacaoDto } from './validacaoDto.model';

export class DocumentResult {
  id: number;
  nomeDocumento: string;
  status: string;
  statusServico: string;
  statusObservacoes: string;
  siglaDocumento: string;
  dataPedido: string;
  respostaJson: string;
  erro: string;
  numTentativas: number;
  dataResposta: string;
  dataConclusaoProcessamento: string;
  urlDocumentoS3: string;
  statusHomologacao: string;
  pesoHomologacao: number;
  regraDocumento: string;
  controleDocumento: string;
  tipoDocumento: string;
  cnpj: string;
  validacaoDto: ValidacaoDto;
}

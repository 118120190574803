import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StartScanningEvent {

    public startScanningEvent = new Subject<any>();

    constructor() { }
}

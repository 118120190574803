import { Paises } from "./paises.model";

export class Template{
    id: number;
    nomeTemplate: string;
    descricaoTemplate: string;
    status: string;
    ativo: boolean;
    versaoTemplate: string;
    dataCriacaoTemplate: string;
    dataInicialExecucao: string;
    dataFinalExecucao: string;
    pais: Paises;
    individuoEntidade: string;
    individuo: boolean;
    entidade: boolean;
}
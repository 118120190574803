import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { AuthenticationService } from '../services/auth/authentication.service';
import { ResponsePageable } from '../models/responsePageable.model';

@Injectable({ providedIn: 'root' })

export class CertificateService {
    //   fullApiUrl = environment.fullApiUrl;
      partialApiUrl = environment.partialApiUrl;
      constructor(
        private http: HttpClient,
        private authentication: AuthenticationService
      ) {}

      public getCertificates(): Observable<ResponsePageable> {
        return this.http.get<ResponsePageable>(this.partialApiUrl + '/certificates?size=50&ativo=true');
      }
}

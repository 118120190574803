import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../services/auth/authentication.service';
import { Observable } from 'rxjs';
import { ResponsePageable } from '../models/responsePageable.model';
import { CartaoCnpj } from '../models/cartao.cnpj.model';

@Injectable({ providedIn: 'root' })
export class ScannerService {

  cartaoCnpj: CartaoCnpj;

  fullApiUrl = environment.fullApiUrl;
  partialApiUrl = environment.partialApiUrl;
  wolffishApiUrl = environment.wolffishApiUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  idCustomer;

  constructor(private http: HttpClient, private auth: AuthenticationService) {
    this.auth.tokenPayLoadSubject
      .asObservable()
      .subscribe(x => (this.idCustomer = x.customer));
    // console.log(this.idCustomer);
  }

  getCountries(): Observable<any>{
    return this.http.get<any>(this.partialApiUrl + '/sts/paises');
  }

  startScanner(startScanner): Observable<any> {
    //console.log(startScanner);
    return this.http.post<any>(
      this.partialApiUrl + '/start-scanning',
      startScanner,
      this.httpOptions
    );
  }

  startScannerWolffish(startScanner): Observable<any> {
    return this.http.post<any>(
      this.wolffishApiUrl + '/wolffish',
      startScanner,
      this.httpOptions
    );
  }

  getScanners(pageIndex: number, pageSize: number): Observable<ResponsePageable> {
    return this.http.get<ResponsePageable>(this.partialApiUrl + '/orders?tipoPedidoa=SCANNER&tipoPedidob=SCANNERINTEGRACAO&page=' + pageIndex + '&size=' + pageSize + '&sort=desc');
  }

  getScannerByCnpj(cnpj: string): Observable<ResponsePageable> {
    return this.http.get<ResponsePageable>(this.partialApiUrl + '/orders?cnpj=' + cnpj + '&tipoPedidoa=SCANNER&tipoPedidob=SCANNERINTEGRACAO');
  }

  getScannerWolffish(customerId: number, pageIndex: number, pageSize: number): Observable<ResponsePageable> {
    return this.http.get<ResponsePageable>(this.wolffishApiUrl + `/wolffish/customer/${customerId}?page=` + pageIndex + '&size=' + pageSize + '&sort=desc');
  }

  getScannerWolffishByCnpj(customerId: number, cnpj: string): Observable<ResponsePageable> {
    return this.http.get<ResponsePageable>(this.wolffishApiUrl + `/wolffish/customer/${customerId}/search?cnpj=${cnpj}`);
  }

}

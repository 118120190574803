import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { AuthenticationService } from '../services/auth/authentication.service';
import { Order } from '../models/order.model';

@Injectable({ providedIn: 'root' })
export class OrdersService {
//   fullApiUrl = environment.fullApiUrl;
  partialApiUrl = environment.partialApiUrl;
  constructor(
    private http: HttpClient,
    private authentication: AuthenticationService
  ) {}

  public getOrder(idOrder: string): Observable<Order> {
    return this.http.get<Order>(this.partialApiUrl + '/orders/' + idOrder);
  }

//   public getCurrentCustomer(): Observable<Customer> {
//     this.getOneCustomer(
//       this.authenticationService.tokenPayLoadSubject.value.customer
//     ).subscribe(customer => {
//       this.currentCustomerSubject.next(customer);
//     });
//     return this.currentCustomerSubject.asObservable();
//   }

  // ..sts/customers/{customerId}
//   public putCustomer(customer: Customer): Observable<Customer> {
//     console.log(customer)
//     return this.http.put<Customer>(
//       this.fullApiUrl +
//         '/customers/' +
//         this.authenticationService.tokenPayLoadSubject.value.customer,
//       customer
//     );
//   }
}

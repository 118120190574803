import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { AuthenticationService } from '../services/auth/authentication.service';
import { ResponsePageable } from 'app/shared/models/responsePageable.model';

@Injectable({
  providedIn: 'root',
})
export class DetectiveControlService {
  apiUrl = environment.partialApiUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  idCustomer;
  razaoSocial: string = 'Processando';

  constructor(private http: HttpClient, private auth: AuthenticationService) {
    this.auth.tokenPayLoadSubject
      .asObservable()
      .subscribe((x) => (this.idCustomer = x.customer));
  }

  startDetection(startQualification): Observable<any> {
    return this.http.post<any>(
      this.apiUrl + '/detective-control',
      startQualification
    );
  }

  getControls(
    pageIndex: number,
    pageSize: number
  ): Observable<ResponsePageable> {
    return this.http.get<ResponsePageable>(
      this.apiUrl +
        '/detective-control?page=' +
        pageIndex +
        '&size=' +
        pageSize +
        '&sort=desc'
    );
  }

  getControl(id: string): Observable<any> {
    return this.http.get<void>(this.apiUrl + '/detective-control/' + id);
  }

  getControlsByName(name: string): Observable<any> {
    return this.http.get<void>(this.apiUrl + '/detective-control?name=' + name);
  }

  //UPLOAD

  startProcessing(csvData): Observable<any> {
    return this.http.post<void>(this.apiUrl + '/lake/upload', csvData);
  }

  clearRegister(): Observable<any> {
    return this.http.delete<void>(this.apiUrl + '/lake');
  }

  countRecords(): Observable<any> {
    return this.http.get<void>(this.apiUrl + '/lake/count');
  }

  getStatus(): Observable<any> {
    return this.http.get<void>(this.apiUrl + '/lake-log');
  }
}

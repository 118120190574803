import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, CollectionReference, DocumentReference } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';
import { SupplierFileModel } from 'app/shared/models/firestore/supplier-file-data.model';

@Injectable({ providedIn: 'root' })
export class SupplierFileDataService {

  private readonly supplierFilePath: string = "supplier-file";

  public fileItemsObservable: Observable<SupplierFileModel[]>;


  constructor(
    private authenticationService: AuthenticationService,
    private firestore: AngularFirestore
    ) {}


  addFile(fileData: SupplierFileModel): Promise<DocumentReference> {
    return this.firestore.collection(this.supplierFilePath).add(Object.assign({}, fileData));
  }

  updateFile(fileData: SupplierFileModel, fileId: string) {
    return this.firestore.collection(this.supplierFilePath).doc('/' + fileId).update(Object.assign({}, fileData))
  }

  getAllFilesByClienteAndFornecedor(fornecedorId: number): Observable<SupplierFileModel[]> {
    this.fileItemsObservable = null;
    let clienteId: number = this.authenticationService.tokenPayLoadSubject.value.customer;
    let supplierFileCollection: AngularFirestoreCollection<SupplierFileModel> = this.firestore.collection(this.supplierFilePath, (ref:CollectionReference ) =>
    ref.where("clienteId", "==", clienteId).where("fornecedorId", "==", fornecedorId ));

    this.fileItemsObservable = supplierFileCollection.valueChanges({ idField: 'id' });
    return this.fileItemsObservable;
  }

  deleteFileById(fileId: string){
    return this.firestore.collection(this.supplierFilePath).doc(fileId).delete();
  }
}
